.rounded-full {
  border-radius: $full-border-radius !important;

  &-left {
    border-top-left-radius: $full-border-radius !important;
    border-bottom-left-radius: $full-border-radius !important;
  }

  &-right {
    border-top-right-radius: $full-border-radius !important;
    border-bottom-right-radius: $full-border-radius !important;
  }
}



.show-uber {
  display: none;

  @media screen and (min-width: 1920px) {
    display: block;

  }
}



.ms-2\.5 {
  margin-left: 0.75rem;
}
.ms-2\.75 {
  margin-left: 0.85rem;
}

.cursor {
  &--pointer {
    cursor: pointer;
  }

  &--not-allowed {
    cursor: not-allowed;
  }

  &--zoom-in {
    cursor: zoom-in;
  }

  &--zoom-out {
    cursor: zoom-out;
  }

  &--zoom-in-out {
    cursor: zoom-in-out;
  }

  &--grab {
    cursor: grab;
  }

  &--grabbing {
    cursor: grabbing;
  }

  &--move {
    cursor: move;
  }

  &--crosshair {
    cursor: crosshair;
  }

  &--text {
    cursor: text;
  }

  &--wait {
    cursor: wait;
  }

  &--help {
    cursor: help;
  }


}
