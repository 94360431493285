.elevated {
  $hsl-calc: hsl(0deg 0% 0% / 0.1);
  $shadow-elevation-low:
    0px 0.8px 5px $hsl-calc,
    0px 0.9px 0.8px -1.2px $hsl-calc,
    0px 1.8px 2px -2.5px $hsl-calc;
  $shadow-elevation-medium:
    0px 1.1px 5px $hsl-calc,
    0px 1.5px 1.7px -0.8px $hsl-calc,
    0px 3.6px 4.1px -1.7px $hsl-calc,
    0.1px 8.8px 9.9px -2.5px $hsl-calc;
  $shadow-elevation-high:
    0px 0.4px 5px $hsl-calc,
    0px 2.6px 2.9px -0.4px $hsl-calc,
    0px 4.8px 5.4px -0.7px $hsl-calc,
    0.1px 7.9px 8.9px -1.1px $hsl-calc,
    0.1px 12.6px 14.2px -1.4px $hsl-calc,
    0.2px 19.7px 22.2px -1.8px $hsl-calc,
    0.2px 30px 33.8px -2.1px $hsl-calc,
    0.4px 44.1px 49.6px -2.5px $hsl-calc;



  &--low {
    box-shadow: $shadow-elevation-low;
  }

  &--medium {
    box-shadow: $shadow-elevation-medium;
  }

  &--high {
    box-shadow: $shadow-elevation-high;
  }
}
