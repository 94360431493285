@import '../node_modules/@syncfusion/ej2-angular-base/styles/bootstrap5.3.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'custom-bootstrap.scss';
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import 'src/app/styles/global.scss';

@import 'node_modules/ngx-toastr/toastr.css';
@import '~vis-timeline/dist/vis-timeline-graph2d.min.css';

@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/bootstrap5.3.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/bootstrap5.3.css';

/* start of global syncfusion styling for grid, accordion, toolbar, sidebar & chiplist */
.e-icons {
  font-family: 'e-icons' !important;
}

.e-sidebar {
  height: calc(100vh - 128px);
  border-radius: 8px;
  border: 1px solid #e0e0e0 !important;
  padding: 4px;
  position: fixed;
  top: 120px !important;
  left: 80px !important;
  background-color: white;

  .e-list-parent {
    padding: 0;
  }
}

.e-toolbar {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: white;

  .e-toolbar-items {
    background-color: white;

    .e-toolbar-item {
      padding: 0 !important;
      margin-right: 4px !important;

      > button {
        background-color: white;
        border-radius: 4px;
        padding: 0 !important;

        &:focus {
          box-shadow: none;
        }

        &.e-icon-btn:hover {
          background-color: #e0e0e0;
        }

        &:hover .e-icons {
          color: black;
          background-color: #e0e0e0;
        }

        &:hover .e-tbar-btn-text {
          background: white;
          cursor: default;
        }

        > span {
          line-height: 32px;
          color: black !important;
          font-size: 16px;

          &:hover {
            color: black;
          }
        }

        .e-tbar-menu-icon {
          line-height: 10px !important;
        }

        > .e-tbar-menu-icon:before {
          content: url('./assets/images/bars-solid.svg');
        }
      }
    }
  }
}

.e-accordion {
  background-color: #f5f5f5;
  border: none;

  .e-acrdn-item {
    margin-bottom: 8px;
    border: none;
    border-radius: 8px !important;
    border: 2px solid #CBDEE7 !important;

    .e-acrdn-header {
      padding: 0;
      border: none !important;
      border-radius: 8px !important;

      .e-acrdn-header-content {
        display: flex;

        > button {
          display: flex;
          width: 100%;
          align-items: baseline;
          background: white;
          border: none;
        }
      }

      .e-toggle-icon  {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .e-icons {
          color: #377a94;
          margin-top: 6px;
        }
      }
    }

    .e-acrdn-content {
      padding: 0;
      background-color: white !important;
      border-radius: 8px;
    }
  }

  .e-acrdn-item:last-child {
    margin-bottom: 0;
  }

  .e-acrdn-item.e-select.e-selected {
    border: 2px solid #377A94 !important;
  }
}

.e-grid {
  border-radius: 8px;

  .e-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .e-toolbar-item > button {
      &:hover .e-tbar-btn-text {
        background-color: #e0e0e0;
        cursor: pointer;
      }

      .e-icons {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .e-tbar-btn-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      > span {
        line-height: 24px !important;
        padding: 4px !important;
      }
    }
  }

  .e-headercell {
    line-height: 24px;

    .e-headercelldiv {
      display: contents !important;
    }

    .e-sortfilterdiv {
      width: 13px;
    }

    .e-ascending {
      margin-top: 6px !important;

      &:before {
        content: url('./assets/images/sort-up-solid.svg');
      }
    }

    .e-descending {
      margin-top: 0 !important;

      &:before {
        content: url('./assets/images/sort-down-solid.svg');
      }
    }
  }

  .e-row:hover {
    background-color: #f7fafb !important;
  }

  .e-rowcell {
    padding: 8px;

    &:nth-child(3n+0) .e-chip-list > div {
      background-color: rgba(180, 221, 141, 0.2);
    }

    &:nth-child(3n+1) .e-chip-list > div {
      background-color: #EEEEEE;
    }

    &:nth-child(3n+2) .e-chip-list > div {
      background-color: rgba(203, 222, 231, 0.5);
    }

    .e-chip-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      padding: 0;

      > div {
        border-radius: 8px;
        border: none;
        padding: 1.1rem .5rem;
        margin: 0;
      }
    }
  }

  .e-groupdroparea {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .e-groupdroparea ~ .e-toolbar {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .e-gridpager {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .e-parentmsgbar {
      display: none !important;
    }
  }
}

//this removes the border width on the left side of the column that is shown right after the hidden column (id)
.e-grid:not(.sf-grid).e-default.e-bothlines .e-headercell.e-hide + .e-headercell, .e-grid.e-bothlines .e-rowcell.e-hide + .e-rowcell {
  border-left-width: 0 !important;
}

//this solves a problem on dialog footer cancel buttons background-color on hover being affected by the override of bootstrap below
.e-footer-content .e-btn.e-flat:not(.e-primary):hover {
  background-color: #5c636a !important;
}

/* Override Bootstrap's variables */
:root {
  --color-sf-table-bg-color-hover: unset; //background-color on table rows when hovering
  --color-sf-secondary-bg-color-hover: unset; //a background-color edge problem on toolbar buttons
}
/* end of global syncfusion styling for freight and pricing */

.table {
  --bs-table-bg: unset;
  --bs-table-color: unset;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control:disabled, .form-control[readonly] {
  background-color: $input-group-text-background;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.overlay{
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
}

label {
  font-size: $font-size--smaller;
  margin-top: 10px;
  margin-bottom: 0;
}
.accept-btn{
  width: 100px!important;
}

.btn-container{
  padding: 15px;
}

.btn-success, .btn-success:disabled, .btn-success:hover, .btn-outline-secondary:hover {
  color: white;
}

.z-depth-hover :hover {
  box-shadow: 0 3px 3px 3px rgba(0, 0, 0, .2), 0 3px 3px 0 rgba(0, 0, 0, .14), 0 3px 3px 0 rgba(0, 0, 0, .12) !important;
  transform: translate3d(0, 0, 0);
  transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}

.widget-margin {
  margin: 15px 0 15px 0!important;
}

.delete {
  float: right; margin:10px 15px -15px 0; position: relative; z-index: 2;
}

.none-text {
  margin-top: 10px;
  font-size: $font-size--mini;
  font-style: italic;
  opacity: 0.8;
}

.required-text {
  margin-top: -10px;
  font-size: $font-size--tiny !important;
  color: $error-color !important;
}

.fc-timeline-slot-cushion {
  color: black;
}

.fc-button-primary{
  background-color: $brand-primary-color !important;
  border: transparent !important;
}

.fc-button-active {
    background-color: darken($brand-primary-color, 5%) !important;
    border: transparent !important;
}

ngb-pagination .page-item.active .page-link {
  background-color: $primary-background-color;
  border-color: $primary-background-color;
  color: white;
  position: unset;
}

ngb-pagination .page-item .page-link {
  color: black;
}

.accordion, .accordion-body {
  padding: 15px;
}

.detail-content .accordion, .detail-content .accordion-body {
  padding: 0;
}

.accordion-button, .accordion-button::after {
  background-color: $primary-background-color !important;
  color: white !important;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  transform: rotate(-180deg) !important;
}

.plus-btn-right {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: .8rem;
}

.input-group-append {
  display: flex;
}

.input-group-append>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.width-fix {
  max-width: 25%;
}

button.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: $input-group-text-background;
}

.input-group input[type=search] {
  margin-bottom: 0;
}

.card {
  padding: 0 15px;
}

.modal-content .card {
  padding: 0;
}

.detail-content .card {
  padding: 0;
}

.card-body form {
  padding: 0 15px;
}

.full-height {
  height: 100vh!important;
}

.full-width {
  width: 100% !important;
}

.tippy-box {
  color: #fff;
  border-radius: 4px;
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: rgba(0, 0, 0, .7);
  font-family: Roboto, sans-serif;
  font-size: $font-size--base;
}

.fc-toolbar-title {
  font-size: $heading-one-font-size !important; // 20px
}

.fc .fc-bg-event {
  opacity: .6!important;
}

.fc-col-header-cell-cushion {
  color: $font-color;
  text-decoration: none;
  background-color: initial;
}

.fc-scrollgrid-shrink-cushion{
  color: rgb(0,0,0);
  text-decoration: none;
  background-color: initial;
}

.to-bottom {
  position: absolute;
  bottom: 0;
}

.transition-widget{
  transition: all 0.3s;
}

// css filter: https://codepen.io/sosuke/pen/Pjoqqp
// .color-red{
//   color: #8e2b3c !important;
//   filter: invert(23%) sepia(11%) saturate(6849%) hue-rotate(315deg) brightness(65%) contrast(94%) !important;
// }

// .color-green{
//   color: #709A66 !important;
//   filter: invert(55%) sepia(43%) saturate(320%) hue-rotate(63deg) brightness(94%) contrast(87%) !important;
// }

// .color-gold{
//   color:#C3A24A !important;
//   filter: invert(62%) sepia(66%) saturate(365%) hue-rotate(6deg) brightness(94%) contrast(86%) !important;
// }



.form-spec-input{
    width: auto;
    margin-left: 0;
    margin-top: 7px;
    height: calc(1em + 2px);
    display: block;
}

input[type='checkbox'][readonly]{
  pointer-events: none;
}

.text-center{
  text-align: center;
}

.vis-item.rest {
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  border-radius: 0;
}

.vis-item.driving {
  background-color: $driving-green-color;
  border-color: $driving-green-color;
  border-radius: 0;
}

.vis-item.work {
  background-color: $work-yellow-color;
  border-color: $work-yellow-color;
  border-radius: 0;
}

.vis-item.avail {
  background-color: $available-red-color;
  border-color: $available-red-color;
  border-radius: 0;
}

.vis-item.restBorder {
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  border-left-color: $primary-background-color;
  border-style: dotted;
  border-width: 2px;
  border-bottom-width: 0;
  border-radius: 0;
}

.vis-item.drivingBorder {
  background-color: $driving-green-color;
  border-color: $driving-green-color;
  border-left-color: $primary-background-color;
  border-style: dotted;
  border-width: 2px;
  border-bottom-width: 0;
  border-radius: 0;
}

.vis-item.workBorder {
  background-color: $work-yellow-color;
  border-color: $work-yellow-color;
  border-left-color: $primary-background-color;
  border-style: dotted;
  border-width: 2px;
  border-bottom-width: 0;
  border-radius: 0;
}

.vis-item.availBorder {
  background-color: $available-red-color;
  border-color: $available-red-color;
  border-left-color: $primary-background-color;
  border-style: dotted;
  border-width: 2px;
  border-bottom-width: 0;
  border-radius: 0;
}

.vis-timeline {
  border: none !important;
}

.vis-tooltip {
  background: $primary-background-color !important;
  border-radius: 8px !important;
  font-family: $body-font !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: $font-size--extra-tiny !important;
  line-height: 1rem !important;
  color: white !important;
}

.reading-block {
  overflow: visible !important;
  font-weight: 600 !important;
  color: white;
}

.marker-label-text {
  border-radius: 15px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: white;
  text-align: center;
  white-space: nowrap;
  opacity: .8;
}

.detail-content {
  padding: 0;
}

::ng-deep {
  .custom-slider .ngx-slider .ngx-slider-bar {
    background: $ngx-slider-bar-background;
    height: 2px;
  }

  .custom-slider .ngx-slider .ngx-slider-selection {
    background: $ngx-slider-selection-background;
  }

  .custom-slider .ngx-slider .ngx-slider-pointer {
    width: 8px;
    height: 16px;
    top: auto; /* to remove the default positioning */
    bottom: 0;
    background-color: $ngx-slider-pointer-background;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .custom-slider .ngx-slider .ngx-slider-pointer:after {
    display: none;
  }

  .custom-slider .ngx-slider .ngx-slider-bubble {
    bottom: 14px;
  }

  .custom-slider .ngx-slider .ngx-slider-limit {
    font-weight: bold;
    color: $ngx-slider-selection-background;
  }

  .custom-slider .ngx-slider .ngx-slider-tick {
    width: 1px;
    height: 10px;
    margin-left: 4px;
    border-radius: 0;
    background: $ngx-slider-bar-background;
    top: -1px;
  }

  .custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
    background: $ngx-slider-selection-background;
  }
}


.cursor-pointer{
  cursor: pointer;
}
