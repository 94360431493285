.btn {
  border-radius: $full-border-radius !important;

  &-success {
    border-radius: $full-border-radius !important;
  }
}




.btn-group {
  > .btn {
    border-radius: 0 !important;

    &:only-child {
      border-radius: $full-border-radius !important;
    }
  }

  .dropdown {
    > .btn {
      border-radius: 0 !important;

      &.end {
        border-top-right-radius: $full-border-radius !important;
        border-bottom-right-radius: $full-border-radius !important;
      }
    }
  }

  .start {
    border-top-left-radius: $full-border-radius !important;
    border-bottom-left-radius: $full-border-radius !important;

  }

  .end {
    border-top-right-radius: $full-border-radius !important;
    border-bottom-right-radius: $full-border-radius !important;
  }
}







.fc-toolbar-chunk {
  .fc-button {
    border-radius: $full-border-radius !important;
  }
}
.fc-button-group {
  > .fc-button {
    border-radius: 0 !important;
  }

  > .fc-button:first-child {
    border-top-left-radius: $full-border-radius !important;
    border-bottom-left-radius: $full-border-radius !important;
  }

  > .fc-button:last-child {
    border-top-right-radius: $full-border-radius !important;
    border-bottom-right-radius: $full-border-radius !important;
  }
}


.btn-check:checked+.btn {
  background-color: $brand-default-color !important;
  border-color: $brand-default-color !important;
}
