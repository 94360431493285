$primary-background-color: $brand-primary-color !default;
$font-color: $brand-default-color !default;
$error-color: #e04e38 !default;

// navbar
$push-menu-background-color: $brand-default-color !default;
$navbar-background-color: #fff;
$navbar-text-color: #1f2d3d !default;

$driving-green-color: $brand-success-color;
$work-yellow-color: $brand-warning-color;
$available-red-color: $brand-danger-color;

// ngx-slider
$ngx-slider-bar-background: #ffe4d1 !default;
$ngx-slider-selection-background: orange !default;
$ngx-slider-pointer-background: #333 !default;

// input group texts
$input-group-text-background: #e9ecef !default;

$sidebar-link-hover-background: lighten($brand-primary-color, 5%);
// sidebar
@if $theme == 'light' {
  $sidebar-link-hover-background: lighten($brand-primary-color, 5%) !default;
} @else {
  $sidebar-link-hover-background: darken($brand-primary-color, 5%) !default;
}


$sidebar-link-color: #c2c7d0 !default;
$sidebar-link-font-size: calc(13rem/16) !default;
$sidebar-nav-icon-color: $sidebar-link-color;
$sidebar-width: 242px !default;
$sidebar-brand-image-width: 157.43px !default;


// time-statement
$time-statement-opacity: 0.6 !default;
$time-statement-success-background: rgba($brand-success-color, $time-statement-opacity) !default;
$time-statement-warning-background: rgba($brand-warning-color, $time-statement-opacity) !default;
$time-statement-danger-background: rgba($brand-danger-color, $time-statement-opacity) !default;

$time-statement-girls-night-out-background: #ff69b4;
$time-statement-girls-night-out-dark-background: #a30454;

$time-statement-deep-into-the-jungle-light-background: #008b8b;
$time-statement-deep-into-the-jungle-background: #004949;


// scrollbar
$scrollbar-thumb-bg: #576f7b;
$scrollbar-thumb-hover-bg: #64808E;


$medium-slate-blue: #766DF4;


// table
$table-background-color: #f5f6f8 !default;
$table-border-color: #dae6ed !default;

