html {
  font-family: $body-font;
  font-size: $base-font;
}

body {
  font-family: $body-font;
  font-size: $font-size--smaller;
}

.heading-font {
  font-family: $heading-font !important;
}

.body-font {
  font-family: $body-font !important;
}


.brand-font-color {
  color: $font-color;
}

* {
  font-family: $body-font;
}

::ng-deep {
  h1, h2, h3, h4, h5, h6 {
    font-family: $heading-font !important;
    font-weight: 500;
    color: $font-color;
  }
}

h1 {
  font-size: $heading-one-font-size;
  font-weight: 400;

  &.medium {
    font-weight: 500;
  }
}

h2 {
  font-size: $heading-two-font-size;
}

h3 {
  font-size: $heading-three-font-size;
}

h4 {
  font-size: $heading-four-font-size;
}

h5 {
  font-size: $heading-five-font-size;
}

h6 {
  font-size: $heading-six-font-size;
}

.btn,
table thead,
table thead *,
.fc-button {
  font-family: $heading-font !important;
}


.italic {
  font-style: italic;
}




.fs-1\.5 {
  font-size: calc($font-size--base * 2.25); // 36px if base is 16px
}

.fs-5\.5 {
  font-size: calc($font-size--base * 1.125); // 18px if base is 16px
}

.fs-small {
  font-size: calc($font-size--base * 0.8125); // 13px if base is 16px
}
