$font-size-base-value: 16;
$font-size--base: #{$font-size-base-value}px !default;
$base-font: calc(16rem/$font-size-base-value) !default;
$heading-one-font-size: calc(20rem/$font-size-base-value) !default;
$heading-two-font-size: calc(18rem/$font-size-base-value) !default;
$heading-three-font-size: calc(16rem/$font-size-base-value) !default;
$heading-four-font-size: calc(14rem/$font-size-base-value) !default;
$heading-five-font-size: calc(12rem/$font-size-base-value) !default;
$heading-six-font-size: calc(11rem/$font-size-base-value) !default;

$font-size--largest: calc(19rem/$font-size-base-value) !default;
$font-size--larger: calc(18rem/$font-size-base-value) !default;
$font-size--large: calc(17rem/$font-size-base-value) !default;
$font-size--small: calc(15rem/$font-size-base-value) !default;
$font-size--smaller: calc(14rem/$font-size-base-value) !default;
$font-size--tiny: calc(13rem/$font-size-base-value) !default;
$font-size--extra-tiny: calc(12rem/$font-size-base-value) !default;
$font-size--mini: calc(11rem/$font-size-base-value) !default;

$body-font: 'Open Sans', sans-serif !default;
$heading-font: 'Kanit', sans-serif !default;


// IMPORTANT: UPDATE VALUES IN CONSTANTS.TS WHEN UPDATING THESE VALUES
$theme-colors: (
  "primary": #387a93,
  "warning": #ffd45b,
  "success": #709A68,
  "danger": #B54B5D,
  "default": #2E5261,
  "light": #cbdee7,
  'lightGray': #b3b3b3,
  "secondary": #49839c,
  "row": #f7f7f7,
  "deep-blue": #112e50,
  "bermuda-onion": #9b618f, // https://colornamer.robertcooper.me - name of the hex color
  "dark-rose": #B54B5D, // https://colornamer.robertcooper.me - name of the hex color
  "mizu": #6ec1e4, // https://colornamer.robertcooper.me - name of the hex color
  "mizu-dark": #4fa1c4, // https://colornamer.robertcooper.me - name of the hex color
  'orochimaru': #d9d9d9, // https://colornamer.robertcooper.me - name of the hex color
  'lightGray7': #777777,
  'lightGray8': #888888
);

$theme: 'light' !default;


// TODO: I want to sort out the different grays we use, but they are used so many different spots that it's hard to do on my own
// $gray7: #CBDEE7;
// $gray12: #dae6ed;
// $gray8: #eff4f6;
// $gray11: #f5f6f8;
// $gray2: #f7f7f7;
// $gray0: #EEEEEE;
// $gray9: #E8E8E8;
// $gray3: #d9d9d9;
// $gray6: #C4C4C4;
// $gray0: #B8C1C5;
// $gray1: #b3b3b3;
// $gray5: #888888;
// $gray4: #777777;
// $gray4: #a3a5a8;















$brand-primary-color: map-get($theme-colors, "primary") !default;
$brand-success-color: map-get($theme-colors, "success") !default;
$brand-warning-color: map-get($theme-colors, "warning") !default;
$brand-danger-color: map-get($theme-colors, "danger") !default;
$brand-default-color: map-get($theme-colors, "default") !default;
$brand-deepblue-color: map-get($theme-colors, "deep-blue") !default;
$brand-bermuda-onion-color: map-get($theme-colors, "bermuda-onion") !default;
$brand-orochimari-color: map-get($theme-colors, "orochimaru") !default;
$brand-dark-rose-color: map-get($theme-colors, "dark-rose") !default;
$brand-mizu-color: map-get($theme-colors, "mizu") !default;
$brand-mizu-dark-color: map-get($theme-colors, "mizu") !default;
$brand-lightgray-color: map-get($theme-colors, "lightGray") !default;
$brand-lightgray7-color: map-get($theme-colors, "lightGray7") !default;
$brand-lightgray8-color: map-get($theme-colors, "lightGray8") !default;
$brand-light-color: map-get($theme-colors, 'light') !default;


$brand-aquamarine-color: #7fffd4 !default;


$full-border-radius: 9999px;

$breakpoints: (
  'xsmall': 576px, //max-width
  'small': 576px, //min-width
  'medium': 768px,
  'large': 992px,
  'xlarge': 1200px,
  'xxlarge': 1400px
) !default;


:root {
  --html-font-size: #{$font-size--base};
  --bs-btn-active-bg: #{$brand-default-color};
  --bs-btn-active-border-color: #{$brand-default-color};

}
