.border-opacity-0 {
  border-color: rgba(0, 0, 0, 0) !important;
}


.border.border {
  &--dashed {
    border: var(--bs-border-width) dashed rgba(119, 119, 119, .5) !important;
  }
}
