// @use 'sass:math';

// variables
@import './_variables';
@import './_theme';


// mixins
@import 'mixins/_elevation';

@import './_tools';


// shared
@import 'shared/_typography';
@import 'shared/_borders';
@import 'shared/_buttons';
@import 'shared/_colors';
@import 'shared/_cursor';
@import 'shared/_utility';


.w-full {
  width: 100%;
}

