.bg-white {
  background-color: #fff !important;
}

.text-white {
  color: #fff !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-black {
  color: #000 !important;
}


.bg-default {
  background-color: $brand-default-color !important;
}

.text-default {
  color: $brand-default-color !important;
}

.bg-primary {
  background-color: $brand-primary-color !important;
}

.text-primary {
  color: $brand-primary-color !important;
}
