.cursor {
  &\:pointer {
    cursor: pointer;
  }

  &\:not-allowed {
    cursor: not-allowed;
  }

  &\:zoom-in {
    cursor: zoom-in;
  }

  &\:zoom-out {
    cursor: zoom-out;
  }

  &\:zoom-in-out {
    cursor: zoom-in-out;
  }

  &\:grab {
    cursor: grab;
  }

  &\:grabbing {
    cursor: grabbing;
  }

  &\:move {
    cursor: move;
  }

  &\:crosshair {
    cursor: crosshair;
  }

  &\:text {
    cursor: text;
  }

  &\:wait {
    cursor: wait;
  }

  &\:help {
    cursor: help;
  }
}
